/* global kofiWidgetOverlay */

import React, { useEffect } from 'react';
import '../styles/KoFiWidget.css'; // Ensure this file contains the required CSS for positioning

const KoFiWidget = () => {
    useEffect(() => {
      const script1 = document.createElement('script');
      script1.src = 'https://storage.ko-fi.com/cdn/scripts/overlay-widget.js';
      script1.onload = () => {
        if (window.kofiWidgetOverlay) {
          window.kofiWidgetOverlay.draw('epollslive', {
            'type': 'floating-chat',
            'floating-chat.donateButton.text': 'Support Us',
            'floating-chat.donateButton.background-color': '#00b9fe',
            'floating-chat.donateButton.text-color': '#fff'
          });
  
          // Insert custom styles to adjust position
          const style = document.createElement('style');
          style.innerHTML = `
            .floatingchat-container-wrap { bottom: 50px !important; }
            .floating-chat-kofi-popup-iframe { bottom: 120px !important; }
            .floatingchat-container-wrap-mobi { bottom: 60px !important; }
            .floating-chat-kofi-popup-iframe-mobi { bottom: 120px !important; }
          `;
          document.head.appendChild(style);
        } else {
          console.error('kofiWidgetOverlay is not defined');
        }
      };
      document.body.appendChild(script1);
  
      return () => {
        document.body.removeChild(script1);
      };
    }, []);
  
    return null;
  };
  
  export default KoFiWidget;
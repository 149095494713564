import parsePhoneNumberFromString from 'libphonenumber-js';
import disposable from '@ip1sms/is-disposable-phone-number';

const validateUSPhoneNumber = (phoneNumber) => {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');
  return parsedNumber && parsedNumber.isValid() && parsedNumber.country === 'US';
};

const isDisposablePhoneNumber = (phoneNumber) => {
  return disposable(phoneNumber);
};

export { validateUSPhoneNumber, isDisposablePhoneNumber };
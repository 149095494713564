import React, { useState, useEffect } from 'react';
import AppRoutes from './routes';
import Header from './components/Header';
import Footer from './components/Footer';
import KoFiWidget from './components/KoFiWidget';
import './styles/App.css';

const App = () => {
  const [counter, setCounter] = useState(120);

  useEffect(() => {
    const now = new Date();
    const secondsUntilNextMinute = 120 - now.getSeconds();
    setCounter(secondsUntilNextMinute);

    const countdownInterval = setInterval(() => {
      setCounter((prevCounter) => (prevCounter === 0 ? 120 : prevCounter - 1));
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, []);

  return (
    <div className="App">
      <Header counter={counter} />
      <AppRoutes />
      <KoFiWidget />
      <Footer />
    </div>
  );
};

export default App;

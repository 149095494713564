// src/components/withDeviceDetection.js
import React from 'react';
import { isMobile } from 'react-device-detect';

const withDeviceDetection = (DesktopComponent, MobileComponent) => {
  return (props) => {
    return isMobile ? <MobileComponent {...props} /> : <DesktopComponent {...props} />;
  };
};

export default withDeviceDetection;

// src/pages/Signup.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useAuth from '../../hooks/useAuth';
import styled from 'styled-components';
import { auth, RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider } from '../../services/firebase';
import { validateUSPhoneNumber, isDisposablePhoneNumber } from '../../services/phoneValidator';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};


const app = firebase.initializeApp(firebaseConfig);
// const auth = firebase.getAuth(app);

const states = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
  'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
  'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
  'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
  'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio',
  'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
  'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia',
  'Wisconsin', 'Wyoming'
];

const races = [
  'American Indian/Alaska Native/First Nations', 'Asian', 'Black/African-American',
  'Hispanic/Latinx', 'Native Hawaiian/Pacific Islander', 'Middle Eastern/North African',
  'White', 'Prefer not to say'
];

const parties = [
  'Democrat', 'Republican', 'Green', 'Libertarian', 'Independent'
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  background-color: #f9f9f9;
  min-height: 100vh;
  margin-bottom: 75px;
`;

const Title = styled.h2`
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 500px;  /* Increased width for the container */
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
`;

const Label = styled.label`
  font-size: 1em;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const PhoneInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const PhoneInput = styled(Input)`
  width: 70px; /* Smaller width for phone number inputs */
`;

const PhonePrefix = styled.span`
  line-height: 2.5;
`;

const Select = styled.select`
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const MultiSelect = styled.select`
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: auto; /* Set height to auto */
  max-height: 300px; /* Maximum height to fit all options */
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 1.2em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.p`
  color: red;
`;

const SmallText = styled.p`
  font-size: 0.8em;
  color: #555;
  text-align: left;
  margin-bottom: 20px;
`;

const Signup = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const [username, setUsername] = useState('');
  const [phonePart1, setPhonePart1] = useState('');
  const [phonePart2, setPhonePart2] = useState('');
  const [phonePart3, setPhonePart3] = useState('');
  const [password, setPassword] = useState('');
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [state, setState] = useState('');
  const [race, setRace] = useState([]);
  const [party, setParty] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [error, setError] = useState(null);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [confirmResult, setConfirmResult] = useState(null);
  const captchaRef = useRef(null)

  useEffect(() => {
    const initializeRecaptcha = () => {
      try {
        if (!window.recaptchaVerifier) {
          window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
            size: 'invisible',
            callback: (response) => {
              console.log('reCAPTCHA resolved');
            },
            'expired-callback': () => {
              console.log('reCAPTCHA expired');
            },
            'error-callback': (error) => {
              console.error('reCAPTCHA error:', error);
              setError('reCAPTCHA error');
              window.scrollTo(0, 0);
            },
          });
        }
      } catch (err) {
        console.error('Error initializing reCAPTCHA:', err);
        setError('Error initializing reCAPTCHA');
        window.scrollTo(0, 0);
      }
    };

    initializeRecaptcha();
  }, []);

  const checkUsernameAndPhone = async () => {
    try {
      const phone = `+1${phonePart1}${phonePart2}${phonePart3}`;
      if (!validateUSPhoneNumber(phone)) {
        setError('Please enter a valid US phone number');
        window.scrollTo(0, 0);
        return false;
      }
      if (isDisposablePhoneNumber(phone)) {
        setError('Please enter a non-disposable phone number');
        window.scrollTo(0, 0);
        return false;
      }
      const phoneResponse = await axios.post(`${process.env.REACT_APP_API_URL}/users/check-phone`, { phone });
      if (phoneResponse.data.exists) {
        setError('Phone number already exists');
        window.scrollTo(0, 0);
        return false;
      } else if (phoneResponse.data.message) {
        setError(phoneResponse.data.message);  // Handle invalid or disposable phone number message
        window.scrollTo(0, 0);
        return false;
      }

      const usernameResponse = await axios.post(`${process.env.REACT_APP_API_URL}/users/check-username`, { username });
      if (usernameResponse.data.exists) {
        setError('Username already exists');
        window.scrollTo(0, 0);
        return false;
      }

      return true;
    } catch (error) {
      console.error('Error checking phone/username, please reload and try again:', (error.response?.data?.message) || 'Unknown error');
      setError('Error checking phone/username, please reload and try again:', (error.response?.data?.message) || 'Unknown error');
      window.scrollTo(0, 0);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!/^\d{3}$/.test(phonePart1) || !/^\d{3}$/.test(phonePart2) || !/^\d{4}$/.test(phonePart3)) {
      setError('Please enter a valid phone number');
      window.scrollTo(0, 0);
      return;
    }

    if (parseInt(age) < 18) {
      setError('You must be at least 18 years old to sign up');
      window.scrollTo(0, 0);
      return;
    }

    const isValid = await checkUsernameAndPhone();
    if (!isValid) return;

    setFieldsDisabled(true);

    try {
      const phone = `+1${phonePart1}${phonePart2}${phonePart3}`;
      const appVerifier = window.recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(auth, phone, appVerifier);
      setConfirmResult(confirmationResult);
      setShowVerification(true);
      alert('Verification code sent to your phone.');
    } catch (error) {
      console.error('Error sending code, please reload and try again:', error);
      setError('Error sending SMS, please reload and try again: ' + (error.response?.data?.message) || 'Unknown error');
      setFieldsDisabled(false);
      window.scrollTo(0, 0);
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    if (confirmResult) {
      try {
        var credential = PhoneAuthProvider.credential(confirmResult.verificationId,verificationCode);
        firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(async function(idToken) {
          const phone = `+1${phonePart1}${phonePart2}${phonePart3}`;
          const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/users`, {
            username, phone, password, gender, age, state, race, party, idToken
          });
          localStorage.setItem('authToken', data.token);
          setUser(data);
          navigate('/');
        }).catch(function(error) {
          console.error('Error verifying code, please reload and try again:', (error.response?.data?.message));
          setError('Error verifying code, please reload and try again: ' + (error.response?.data?.message || 'Unknown error'));
          window.scrollTo(0, 0); // Scroll to top on error
        });
      } catch (error) {
        console.error('Error verifying code, please reload and try again:', (error.response?.data?.message));
        setError('Error verifying code, please reload and try again: ' + (error.response?.data?.message || 'Unknown error'));
        window.scrollTo(0, 0); // Scroll to top on error
      }
    }
  };
  
  return (
    <Container>
      <Title>Sign Up</Title>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Username</Label>
          <Input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            disabled={fieldsDisabled}
          />
        </FormGroup>
        <FormGroup>
          <Label>Phone Number</Label>
          <PhoneInputContainer>
            <PhonePrefix>+1</PhonePrefix>
            <PhoneInput
              type="text"
              value={phonePart1}
              onChange={(e) => setPhonePart1(e.target.value)}
              placeholder="XXX"
              maxLength="3"
              required
              disabled={fieldsDisabled}
            />
            <PhonePrefix>-</PhonePrefix>
            <PhoneInput
              type="text"
              value={phonePart2}
              onChange={(e) => setPhonePart2(e.target.value)}
              placeholder="XXX"
              maxLength="3"
              required
              disabled={fieldsDisabled}
            />
            <PhonePrefix>-</PhonePrefix>
            <PhoneInput
              type="text"
              value={phonePart3}
              onChange={(e) => setPhonePart3(e.target.value)}
              placeholder="XXXX"
              maxLength="4"
              required
              disabled={fieldsDisabled}
            />
          </PhoneInputContainer>
        </FormGroup>
        <FormGroup>
          <Label>Password</Label>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={fieldsDisabled}
          />
        </FormGroup>
        <SmallText>
          To improve the effectiveness of our data and ensure we address the needs of our entire community, please let us know the following demographic information. Your responses will allow us to compare anonymous responses across groups of the voting population. We understand human diversity and that the options below may not fully encapsulate one’s identity, so please respond with the closest matching options.
        </SmallText>
        <FormGroup>
          <Label>Age</Label>
            <Input
              type="number"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              min="18"
              max="120"
              required
              disabled={fieldsDisabled}
            />
        </FormGroup>
        <FormGroup>
          <Label>State You Will Vote In</Label>
          <Select value={state} onChange={(e) => setState(e.target.value)} required disabled={fieldsDisabled}>
            <option value="" disabled>Select your state</option>
            {states.map((state, index) => (
              <option key={index} value={state}>{state}</option>
            ))}
          </Select>
        </FormGroup>
        <FormGroup>
          <Label>Gender Identity</Label>
          <Select value={gender} onChange={(e) => setGender(e.target.value)} required disabled={fieldsDisabled}>
            <option value="" disabled>Select your gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </Select>
        </FormGroup>
        <FormGroup>
          <Label>Party Affiliation</Label>
          <Select value={party} onChange={(e) => setParty(e.target.value)} required disabled={fieldsDisabled}>
            <option value="" disabled>Select your political party</option>
            {parties.map((party, index) => (
              <option key={index} value={party}>{party}</option>
            ))}
          </Select>
        </FormGroup>
        <FormGroup>
          <Label>Race/Ethnicity</Label>
          <MultiSelect multiple value={race} onChange={(e) => setRace(Array.from(e.target.selectedOptions, option => option.value))} required disabled={fieldsDisabled}>
            {races.map((race, index) => (
              <option key={index} value={race}>{race}</option>
            ))}
          </MultiSelect>
        </FormGroup>
        <div id="recaptcha-container"></div>
        <Button type="submit" disabled={fieldsDisabled}>Submit</Button>
      </Form>
      {showVerification && (
        <Form onSubmit={handleVerification}>
          <FormGroup>
            <Label>Verification Code</Label>
            <Input
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
            />
          </FormGroup>
          <Button type="submit">Verify</Button>
        </Form>
      )}
    </Container>
  );
};

export default Signup;
// src/pages/mobile/Home.js
import React, { useEffect, useState } from 'react';
import PollList from '../../components/PollList';
import styled from 'styled-components';
import VoteSummaryChart from '../../components/VoteSummaryChart';
import LikelihoodChart from '../../components/LikelihoodChart';
import WeightedVoteChart from '../../components/WeightedVoteChart';
import axios from 'axios';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  overflow: hidden; /* Prevent horizontal scroll */
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const Home = () => {
  const [summaries, setSummaries] = useState([]);

  const fetchSummaries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/vote-summary`);
      const sortedData = response.data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      setSummaries(sortedData);
    } catch (error) {
      console.error('Error fetching vote summaries:', error);
    }
  };

  useEffect(() => {
    fetchSummaries();
    const fetchInterval = setInterval(fetchSummaries, 120000);

    return () => {
      clearInterval(fetchInterval);
    };
  }, []);

  return (
    <Container>
      <Section>
        <WeightedVoteChart summaries={summaries} />
      </Section>
      <Section>
        <VoteSummaryChart summaries={summaries} />
      </Section>
      <Section>
        <LikelihoodChart summaries={summaries} />
      </Section>
      <Section>
        <PollList />
      </Section>
    </Container>
  );
};

export default Home;

// src/pages/PollDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPollById, votePoll } from '../../services/api';
import useAuth from '../../hooks/useAuth';

function PollDetail() {
  const { id } = useParams();
  const { user } = useAuth();
  const [poll, setPoll] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    const fetchPoll = async () => {
      try {
        const data = await getPollById(id);
        setPoll(data);
      } catch (error) {
        console.error('Failed to fetch poll', error);
      }
    };

    fetchPoll();
  }, [id]);

  const handleVote = async (e) => {
    e.preventDefault();
    if (!user) {
      alert('Please log in to vote.');
      return;
    }
    try {
      const token = localStorage.getItem('authToken');
      await votePoll(poll._id, selectedOption, token);
      alert('Vote submitted successfully!');
    } catch (error) {
      console.error('Failed to vote', error);
    }
  };

  if (!poll) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>{poll.question}</h1>
      <form onSubmit={handleVote}>
        {poll.options.map((option) => (
          <div key={option._id}>
            <label>
              <input
                type={poll.isSlider ? 'range' : 'radio'}
                name={poll.question}
                value={option._id}
                checked={selectedOption === option._id}
                onChange={(e) => setSelectedOption(e.target.value)}
                min={poll.isSlider ? 1 : undefined}
                max={poll.isSlider ? 100 : undefined}
              />
              {poll.isSlider ? option.option : option.option} ({option.votes})
            </label>
          </div>
        ))}
        <button type="submit">Vote</button>
      </form>
    </div>
  );
}

export default PollDetail;

// src/components/PollCard.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Card = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  width: 300px;
  position: relative;
`;

const Question = styled.h4`
  margin-bottom: 15px;
  margin-left: 10px;
`;

const QuestionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
`;

const Option = styled.button`
  margin-bottom: 5px;
  padding: 5px;
  border: 1px solid ${props => (props.selected ? '#007bff' : '#ccc')};
  border-radius: 5px;
  cursor: pointer;
  background-color: ${props => (props.selected ? '#007bff' : 'white')};
  color: ${props => (props.selected ? 'white' : 'black')};
  &:hover {
    background-color: ${props => (props.selected ? '#0056b3' : '#f0f0f0')};
  }
`;

const Slider = styled.input`
  width: 100%;
`;

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const SliderLabel = styled.span`
  font-size: 14px;
  margin: 0 10px;
`;

const SliderValue = styled.span`
  font-size: 14px;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
`;

const PollCard = ({ question, options, slider, selectedOption, sliderValue, onInteraction }) => {
  const [selected, setSelected] = useState(selectedOption || '');
  const [value, setValue] = useState(sliderValue || 50);

  useEffect(() => {
    setSelected(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    setValue(sliderValue);
  }, [sliderValue]);

  const handleOptionClick = (option) => {
    setSelected(option);
    onInteraction(option);
  };

  const handleSliderChange = (e) => {
    setValue(e.target.value);
    onInteraction(Number(e.target.value)); // Ensure the value is a number
  };

  return (
    <Card>
      <QuestionContainer>
        <Question>{question}</Question>
        {slider && <SliderValue>{value}%</SliderValue>}
      </QuestionContainer>
      {slider ? (
        <SliderWrapper>
          <SliderLabel>0%</SliderLabel>
          <Slider
            type="range"
            min="0"
            max="100"
            value={value}
            onChange={handleSliderChange}
          />
          <SliderLabel>100%</SliderLabel>
        </SliderWrapper>
      ) : (
        <Options>
          {options.map(option => (
            <Option
              key={option}
              selected={option === selected}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </Option>
          ))}
        </Options>
      )}
    </Card>
  );
};

export default PollCard;

// src/services/analytics.js
import ReactGA from 'react-ga4';

const initializeAnalytics = () => {
  ReactGA.initialize('G-LNSEBETSGR'); // Replace with your GA4 Measurement ID
};

const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
};

export { initializeAnalytics, logPageView };

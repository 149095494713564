// src/components/VoteSummaryChart.js
import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';
import styled from 'styled-components';
import axios from 'axios';

const ChartContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`;

const ChartTitle = styled.h3`
  text-align: center;
`;

const VoteSummaryChart = () => {
  const [summaries, setSummaries] = useState([]);
  const [data, setData] = useState([]);

  const fetchSummaries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/vote-summary`)
      const sortedData = response.data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      setSummaries(sortedData);
    } catch (error) {
      console.error('Error fetching vote summaries:', error);
    }
  };

  useEffect(() => {
    fetchSummaries();
    const now = new Date();
    const secondsUntilNextMinute = 120 - now.getSeconds();
    
    const fetchInterval = setInterval(fetchSummaries, 120000);
    setTimeout(() => {
      fetchSummaries();
      setInterval(fetchSummaries, 120000);
    }, secondsUntilNextMinute * 1000);
    
    return () => {
      clearInterval(fetchInterval);
    };
  }, []);

  useEffect(() => {
    const newData = summaries.map(summary => {
      const totalVotes = summary.voteCounts.trump + summary.voteCounts.harris + summary.voteCounts.other;
      return {
        timestamp: new Date(summary.timestamp).toLocaleString(),
        trump: totalVotes ? (summary.voteCounts.trump / totalVotes) * 100 : 0,
        harris: totalVotes ? (summary.voteCounts.harris / totalVotes) * 100 : 0,
        other: totalVotes ? (summary.voteCounts.other / totalVotes) * 100 : 0,
      };
    });

    setData(newData);
  }, [summaries]);

  return (
    <ChartContainer>
      <ChartTitle>Who will you vote for?</ChartTitle>
      <ResponsiveContainer width="100%" height={350}>
        <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="timestamp" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="trump" stroke="#FF0000" name="Trump Percentage" strokeWidth={2.5} dot={false} />
          <Line type="monotone" dataKey="harris" stroke="#0000FF" name="Harris Percentage" strokeWidth={2.5} dot={false} />
          <Line type="monotone" dataKey="other" stroke="#888888" name="Other Percentage" strokeWidth={2.5} dot={false} />
          <Brush dataKey="timestamp" height={20} stroke="#8884d8" travellerWidth={10} />
        </LineChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default VoteSummaryChart;

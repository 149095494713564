// src/components/WeightedVoteChart.js
import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';
import styled from 'styled-components';
import axios from 'axios';

const ChartContainer = styled.div`
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
`;

const ChartTitle = styled.h3`
  text-align: center;
`;

const WeightedVoteChart = () => {
  const [summaries, setSummaries] = useState([]);
  const [data, setData] = useState([]);
  const [maxWeightedVote, setMaxWeightedVote] = useState(0);

  const fetchSummaries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/vote-summary`)
      const sortedData = response.data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      setSummaries(sortedData);
    } catch (error) {
      console.error('Error fetching vote summaries:', error);
    }
  };

  useEffect(() => {
    fetchSummaries();
    const now = new Date();
    const secondsUntilNextMinute = 120 - now.getSeconds();
    
    const fetchInterval = setInterval(fetchSummaries, 120000);
    setTimeout(() => {
      fetchSummaries();
      setInterval(fetchSummaries, 120000);
    }, secondsUntilNextMinute * 1000);
    
    return () => {
      clearInterval(fetchInterval);
    };
  }, []);

  useEffect(() => {
    const newData = summaries.map(summary => {
      const trumpWeighted = summary.voteCounts.trump * (summary.voteLikelihood.trumpLikelihood / 100);
      const harrisWeighted = summary.voteCounts.harris * (summary.voteLikelihood.harrisLikelihood / 100);
      const otherWeighted = summary.voteCounts.other * (summary.voteLikelihood.otherLikelihood / 100);

      return {
        timestamp: new Date(summary.timestamp).toLocaleString(),
        trumpWeighted,
        harrisWeighted,
        otherWeighted,
      };
    });

    const newMaxWeightedVote = Math.max(
      ...newData.map(d => Math.max(d.trumpWeighted, d.harrisWeighted, d.otherWeighted))
    );

    setData(newData);
    setMaxWeightedVote(newMaxWeightedVote);

  }, [summaries]);

  return (
    <ChartContainer>
      <ChartTitle>Weighted Votes Over Time</ChartTitle>
      <ResponsiveContainer width="100%" height={350}>
        <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="timestamp" />
          <YAxis domain={[0, parseFloat((maxWeightedVote * 1.25).toFixed(2))]} />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="trumpWeighted" stroke="#FF0000" name="Trump Weighted Votes" strokeWidth={2.5} dot={false} />
          <Line type="monotone" dataKey="harrisWeighted" stroke="#0000FF" name="Harris Weighted Votes" strokeWidth={2.5} dot={false} />
          <Line type="monotone" dataKey="otherWeighted" stroke="#888888" name="Other Weighted Votes" strokeWidth={2.5} dot={false} />
          <Brush dataKey="timestamp" height={20} stroke="#8884d8" travellerWidth={10} />
        </LineChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default WeightedVoteChart;

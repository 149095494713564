// src/pages/VerifyPhone.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const VerifyPhone = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [phone] = useState(localStorage.getItem('phone'));

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/users/verify-phone`, {
        phone,
        code
      });
      localStorage.setItem('authToken', data.token);
      navigate('/');
    } catch (error) {
      console.error('Verification failed:', error);
      alert('Verification failed: ' + (error.response?.data?.message || 'Unknown error'));
    }
  };

  return (
    <div>
      <h2>Verify Phone Number</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Verification Code</label>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
        </div>
        <button type="submit">Verify</button>
      </form>
    </div>
  );
};

export default VerifyPhone;

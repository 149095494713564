// src/services/api.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (email, password) => {
  const response = await axios.post(`${API_URL}/users/login`, { email, password });
  return response.data;
};

export const signup = async (userData) => {
  const response = await axios.post(`${API_URL}/users`, userData);
  return response.data;
};

export const fetchPolls = async () => {
  const response = await axios.get(`${API_URL}/polls`);
  return response.data;
};

export const getPollById = async (id) => {
  const response = await axios.get(`${API_URL}/polls/${id}`);
  return response.data;
};

export const votePoll = async (id, optionId, token, sliderValue = null) => {
  const data = sliderValue !== null ? { sliderValue } : { optionId };
  const response = await axios.put(`${API_URL}/polls/${id}/vote`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useAuth from '../hooks/useAuth';

const HeaderContainer = styled.header`
  background-color: #f0f0f0;
  padding: 10px 0px;
  width: 100%; /* Ensure it spans the full viewport width */
  box-sizing: border-box;
  overflow: hidden; /* Prevent horizontal scroll */
`;

const HeaderDesktop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const HeaderMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    margin-right: 0!important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px; /* Ensure padding matches the desktop header */
    padding-left: 20px;
    padding-right: 0px;
  }
`;

const TopSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const CenterSection = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding-left: 75;
  font-size: 18px;
  font-weight: bold;
`;

const CenterSectionMobile = styled.div`
  position: absolute;
  left: 50%;
  padding-left: 20px;
  font-size: 18px;
  font-weight: bold;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  padding-right: 5px;
  gap: 15px;
`;

const NavLink = styled(Link)`
  color: #007bff;
  text-decoration: none;
  font-size: 16px;

  &:hover {
    text-decoration: underline;
  }
`;

const WelcomeMessage = styled.div`
  font-size: 14px;
  color: #555;
  padding-right: 25px;
`;

const LogoutButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    text-decoration: underline;
  }
`;

const Logo = styled.img`
  height: 120px; // Adjust the height as necessary
`;


const Header = ({ counter }) => {
  const { user, logout } = useAuth();

  return (
    <HeaderContainer>
      <HeaderDesktop>
        <LeftSection>
          <NavLink to="/">Home</NavLink>
          {user ? (
            <>
              <LogoutButton onClick={logout}>Log out</LogoutButton>
              <WelcomeMessage>Welcome, {user.username}</WelcomeMessage>
            </>
          ) : (
            <>
              <NavLink to="/login">Login</NavLink>
              <NavLink to="/signup">Sign up</NavLink>
              <WelcomeMessage>Log in or sign up to vote</WelcomeMessage>
            </>
          )}
        </LeftSection>
        <CenterSection>
          <Link to="/">
            <Logo src="/epollslogo-long.png" alt="ePolls.Live Logo" />
          </Link>
        </CenterSection>
        <RightSection>
          <div>Next update in: {counter} seconds</div>
        </RightSection>
      </HeaderDesktop>
      <HeaderMobile>
        <TopSection>
          <CenterSectionMobile>
              <Link to="/">
                <Logo src="/epollslogo-long.png" alt="ePolls.Live Logo" />
              </Link>
          </CenterSectionMobile>
          <RightSection>
            <div>Next update in: {counter} sec</div>
          </RightSection>
        </TopSection>
        <BottomSection>
          <LeftSection>
            <NavLink to="/">Home</NavLink>
            {user ? (
              <>
                <LogoutButton onClick={logout}>Log out</LogoutButton>
                <WelcomeMessage>Welcome, {user.username}</WelcomeMessage>
              </>
            ) : (
              <>
                <NavLink to="/login">Login</NavLink>
                <NavLink to="/signup">Sign up</NavLink>
                <WelcomeMessage>Log in or sign up to vote</WelcomeMessage>
              </>
            )}
          </LeftSection>
        </BottomSection>
      </HeaderMobile>
    </HeaderContainer>
  );
};

export default Header;

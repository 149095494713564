import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import withDeviceDetection from './components/withDeviceDetection';
import { logPageView } from './services/analytics';

// Import desktop and mobile versions of pages
import HomeDesktop from './pages/desktop/Home';
import HomeMobile from './pages/mobile/Home';
import PollDetailDesktop from './pages/desktop/PollDetail';
import PollDetailMobile from './pages/mobile/PollDetail';
import SignupDesktop from './pages/desktop/Signup';
import SignupMobile from './pages/mobile/Signup';
import LoginDesktop from './pages/desktop/Login';
import LoginMobile from './pages/mobile/Login';
import PrivacyPolicyDesktop from './pages/desktop/PrivacyPolicy';
import PrivacyPolicyMobile from './pages/mobile/PrivacyPolicy';
import VerifyPhoneDesktop from './pages/desktop/VerifyPhone';
import VerifyPhoneMobile from './pages/mobile/VerifyPhone';

const HomeComponent = withDeviceDetection(HomeDesktop, HomeMobile);
const PollDetailComponent = withDeviceDetection(PollDetailDesktop, PollDetailMobile);
const SignupComponent = withDeviceDetection(SignupDesktop, SignupMobile);
const LoginComponent = withDeviceDetection(LoginDesktop, LoginMobile);
const PrivacyPolicyComponent = withDeviceDetection(PrivacyPolicyDesktop, PrivacyPolicyMobile);
const VerifyPhoneComponent = withDeviceDetection(VerifyPhoneDesktop, VerifyPhoneMobile);

function AppRoutes() {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<HomeComponent />} />
      <Route path="/poll/:id" element={<PollDetailComponent />} />
      <Route path="/signup" element={<SignupComponent />} />
      <Route path="/login" element={<LoginComponent />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyComponent />} />
      <Route path="/verify-phone" element={<VerifyPhoneComponent />} />
    </Routes>
  );
}

export default AppRoutes;

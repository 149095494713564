// src/pages/PrivacyPolicy.js
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 50px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
`;

const PrivacyPolicy = () => {
  return (
    <Container>
      <Title>Privacy Policy and Terms of Service</Title>
      
      <SectionTitle>Privacy Policy</SectionTitle>
      
      <SectionTitle>Introduction</SectionTitle>
      <Paragraph>
        Welcome to ePolls.Live! We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at pollsdotlive@gmail.com.
      </Paragraph>

      <SectionTitle>Information We Collect</SectionTitle>
      <Paragraph>
        We collect personal information that you voluntarily provide to us when registering on the Website, expressing an interest in obtaining information about us or our products and services, when participating in activities on the Website or otherwise contacting us.
      </Paragraph>
      <Paragraph>
        The personal information that we collect depends on the context of your interactions with us and the Website, the choices you make, and the products and features you use. The personal information we collect may include the following: names, email addresses, and other similar information.
      </Paragraph>

      <SectionTitle>How We Use Your Information</SectionTitle>
      <Paragraph>
        We use personal information collected via our Website for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.
      </Paragraph>
      <Paragraph>
        We use the information we collect or receive to communicate directly with you. We may also send you emails containing newsletters, promotions, and special offers.
      </Paragraph>

      <SectionTitle>Sharing Your Information</SectionTitle>
      <Paragraph>
        We may process or share your data that we hold based on the following legal basis: Consent, Legitimate Interests, Performance of a Contract, Legal Obligations, Vital Interests.
      </Paragraph>

      <SectionTitle>Security of Your Information</SectionTitle>
      <Paragraph>
        We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
      </Paragraph>

      <SectionTitle>Changes to This Privacy Policy</SectionTitle>
      <Paragraph>
        We may update this privacy notice from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.
      </Paragraph>

      <SectionTitle>Contact Us</SectionTitle>
      <Paragraph>
        If you have questions or comments about this policy, you may email us at pollsdotlive@gmail.com.
      </Paragraph>

      <SectionTitle>Terms of Service</SectionTitle>
      
      <SectionTitle>Introduction</SectionTitle>
      <Paragraph>
        These terms of service govern your use of ePolls.Live. By accessing or using our website, you agree to comply with and be bound by these terms. If you do not agree to these terms, please do not use our website.
      </Paragraph>

      <SectionTitle>Use of Our Service</SectionTitle>
      <Paragraph>
        You agree to use our service only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the service. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of dialogue within our service.
      </Paragraph>

      <SectionTitle>User Accounts</SectionTitle>
      <Paragraph>
        When you create an account with us, you must provide us with accurate and complete information. You are responsible for safeguarding your account, and you agree to accept responsibility for all activities or actions that occur under your account.
      </Paragraph>

      <SectionTitle>Termination</SectionTitle>
      <Paragraph>
        We may terminate or suspend access to our service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the terms.
      </Paragraph>

      <SectionTitle>Changes to These Terms</SectionTitle>
      <Paragraph>
        We reserve the right, at our sole discretion, to modify or replace these terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
      </Paragraph>

      <SectionTitle>Contact Us</SectionTitle>
      <Paragraph>
        If you have any questions about these Terms, please contact us at pollsdotlive@gmail.com.
      </Paragraph>
    </Container>
  );
};

export default PrivacyPolicy;

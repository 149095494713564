// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterContainer = styled.footer`
  background-color: #f0f0f0;
  padding: 10px 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;

const LeftText = styled.div`
  font-size: 14px;
`;

const CenterText = styled.div`
  font-size: 14px;
  flex-grow: 1;
  text-align: center;
  padding-right: 50px;
`;

const RightText = styled.div`
  font-size: 14px;
`;

const Hyperlink = styled.a`
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <LeftText>Gordon L and Ian F © 2024</LeftText>
      <CenterText>
        <Link to="/privacy-policy" style={{ color: '#007bff', textDecoration: 'none' }}>
          Privacy Policy
        </Link>
      </CenterText>
      <RightText>
        <Hyperlink href="https://www.vote.org/" target="_blank" rel="noopener noreferrer">
          Register to vote!
        </Hyperlink>
      </RightText>
    </FooterContainer>
  );
};

export default Footer;

// src/components/PollList.js
import React, { useState, useEffect } from 'react';
import PollCard from './PollCard';
import styled from 'styled-components';
import axios from 'axios';
import useAuth from '../hooks/useAuth';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// const PollContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 20px;
//   width: 100%;
//   max-width: 1200px;
//   margin-bottom: 20px;

//   @media (max-width: 768px) {
//     flex-direction: column;
//     align-items: center;
//   }
// `;

const PollContainerDesktop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const PollContainerMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
  }
`;

const VoteStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const VoteStatus = styled.div`
  padding: 10px;
  border: 1px solid red;
  border-radius: 5px;
  color: red;
  text-align: center;
  width: 200px;
  margin-bottom: 10px;
`;

const VoteButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    align-self: center;
  }
`;

const PollList = () => {
  const { user } = useAuth();
  const [voteStatus, setVoteStatus] = useState('Poll not saved');
  const [selectedVote, setSelectedVote] = useState('');
  const [likelihoodVote, setLikelihoodVote] = useState(50);

  useEffect(() => {
    const fetchPreviousVotes = async () => {
      if (!user || !user.token) return;

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/polls/user-votes`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        const { selectedVote, likelihoodVote } = response.data;
        if (selectedVote) {
          setSelectedVote(selectedVote);
          setVoteStatus('Vote submitted successfully!');
        } else {
          setVoteStatus('Poll not saved');
        }

        if (likelihoodVote) setLikelihoodVote(likelihoodVote);
      } catch (error) {
        console.error('Error fetching previous votes:', error);
      }
    };

    fetchPreviousVotes();
  }, [user]);

  const handleInteraction = (value, isSlider) => {
    setVoteStatus('Poll not saved');
    if (isSlider) {
      setLikelihoodVote(value);
    } else {
      setSelectedVote(value);
    }
  };

  const handleVote = async () => {
    if (!user || !user.token) {
      alert('You must be logged in to vote');
      return;
    }

    try {
      const poll1Response = await axios.post(
        `${process.env.REACT_APP_API_URL}/polls/vote`,
        { question: 'Who will you vote for?', value: selectedVote },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const poll2Response = await axios.post(
        `${process.env.REACT_APP_API_URL}/polls/vote`,
        { question: 'How likely are you to vote?', value: likelihoodVote },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (poll1Response.status === 200 && poll2Response.status === 200) {
        setVoteStatus('Vote submitted successfully!');
      }
    } catch (error) {
      console.error('Error submitting vote:', error);
      setVoteStatus('Error submitting vote. Please try again.');
    }
  };

  return (
    <Container>
      <PollContainerDesktop>
        <PollCard
          question="Who will you vote for?"
          options={['Trump', 'Harris', 'Other']}
          selectedOption={selectedVote}
          onInteraction={(value) => handleInteraction(value, false)}
        />
        <VoteStatusContainer>
          <VoteStatus>{voteStatus}</VoteStatus>
          <VoteButton onClick={handleVote}>Vote!</VoteButton>
        </VoteStatusContainer>
        <PollCard
          question="How likely are you to vote?"
          slider
          sliderValue={likelihoodVote}
          onInteraction={(value) => handleInteraction(value, true)}
        />
      </PollContainerDesktop>
      <PollContainerMobile>
        <PollCard
          question="Who will you vote for?"
          options={['Trump', 'Harris', 'Other']}
          selectedOption={selectedVote}
          onInteraction={(value) => handleInteraction(value, false)}
        />
        <PollCard
          question="How likely are you to vote?"
          slider
          sliderValue={likelihoodVote}
          onInteraction={(value) => handleInteraction(value, true)}
        />
        <VoteStatusContainer>
          <VoteStatus>{voteStatus}</VoteStatus>
          <VoteButton onClick={handleVote}>Vote!</VoteButton>
        </VoteStatusContainer>
      </PollContainerMobile>
    </Container>
  );
};

export default PollList;
